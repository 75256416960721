// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap";
import "@fortawesome/fontawesome-free/js/all";
import autosize from 'autosize';
import './add_jquery'
import './progressive-images';
import "./channels"

import ahoy from "ahoy.js";
window.ahoy = ahoy;
document.ahoy = ahoy;

document.addEventListener('turbo:load', () => {
  // navbar 현재 메뉴항목 하이라이트
  var navbarLinks = document.querySelectorAll("a.nav-link");
  var currentURL = window.location.href;

  for (var i = 0; i < navbarLinks.length; i++) {
    var linkURL = navbarLinks[i].href;
    if (currentURL.endsWith(linkURL)) {
      navbarLinks[i].classList.add("active");
   }
  }

  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
  const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

  // Back to top button
  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      $(".back-to-top").fadeIn();
    } else {
      $(".back-to-top").fadeOut();
    }
  });

  $(".back-to-top").click(function () {
    $("html, body").animate({ scrollTop: 0 }, 800);
    return false;
  });
})

// https://medium.com/pragmatic-programmers/animating-turbo-streams-with-animate-css-58fdc93b7814
document.addEventListener("turbo:before-stream-render", (event) => {
  if (event.target.action === "remove") {
    const targetFrame = document.getElementById(event.target.target)
    if (targetFrame.dataset.animateOut) {
      event.preventDefault()
      const elementBeingAnimated = targetFrame.querySelector('div')
      elementBeingAnimated.classList.add(targetFrame.dataset.animateOut)
      elementBeingAnimated.addEventListener("animationend", () => {
        targetFrame.remove()
        console.log('removed')
      })
    }
  }
})
